import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.keys.js";
import Vue from "vue";
import { getData } from "@/services/axios";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import moment from "moment";
Vue.component("VueCtkDateTimePicker", VueCtkDateTimePicker);
export default {
  name: "LogsLogin",
  components: {
    VueCtkDateTimePicker: VueCtkDateTimePicker
  },
  data: function data() {
    return {
      logs: [],
      filteredLogs: [],
      date: {
        start: null,
        end: null
      },
      keyword: "",
      publicOnly: false
    };
  },
  created: function created() {
    this.getLogs();
  },
  methods: {
    getLogs: function getLogs() {
      var _this = this;
      getData("staff_logs?limit=500&orderby=datetime&order=desc").then(function (data) {
        var result = {
          f: "失敗(その他の理由)",
          fp: "失敗（パスワード誤り）",
          fu: "失敗（メールアドレス誤り）",
          s: "成功"
        };
        _this.filteredLogs = _this.logs = data.items.map(function (v) {
          // キーワード検索用に階層移動
          v.name = v.staff ? v.staff.name : "不明（削除されたスタッフの可能性があります）";
          v.result = result[v.type] ? result[v.type] : "不明";
          v.formatted = moment(v.datetime).format("MM月DD日 HH時mm分");
          return v;
        });
      });
    },
    filterData: function filterData(e) {
      var _this2 = this;
      e.preventDefault();
      var logs = this.logs;
      if (this.keyword) {
        logs = logs.filter(function (v) {
          return Object.keys(v).some(function (k) {
            return String(v[k]).indexOf(_this2.keyword) > -1;
          });
        });
      }
      if (this.date.start && this.date.end) {
        var dateStart = moment(this.date.start);
        var dateEnd = moment(this.date.end);
        if (dateStart.isSame(dateEnd)) {
          logs = logs.filter(function (v) {
            return dateStart.isSame(moment(v.datetime).startOf("day"));
          });
        } else {
          logs = logs.filter(function (v) {
            return moment(v.datetime).isBetween(dateStart, dateEnd);
          });
        }
      }
      this.filteredLogs = logs;
    }
  }
};