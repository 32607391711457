var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "switch_button"
  }, [_c('button', {
    staticClass: "switch_button__item",
    class: {
      active: _vm.activeGrade === 'all'
    },
    attrs: {
      "id": "test"
    },
    on: {
      "click": function click($event) {
        return _setup.setActiveGrade('all');
      }
    }
  }, [_vm._v(" すべて ")]), _c('button', {
    staticClass: "switch_button__item",
    class: {
      active: _vm.activeGrade === '保育園'
    },
    attrs: {
      "disabled": true
    },
    on: {
      "click": function click($event) {
        return _setup.setActiveGrade('保育園');
      }
    }
  }, [_vm._v(" 保育園 ")]), _c('button', {
    staticClass: "switch_button__item",
    class: {
      active: _vm.activeGrade === '幼稚園'
    },
    attrs: {
      "disabled": true
    },
    on: {
      "click": function click($event) {
        return _setup.setActiveGrade('幼稚園');
      }
    }
  }, [_vm._v(" 幼稚園 ")]), _c('button', {
    staticClass: "switch_button__item",
    class: {
      active: _vm.activeGrade === 'el'
    },
    attrs: {
      "disabled": !_setup.isHaveSchoolType.el
    },
    on: {
      "click": function click($event) {
        return _setup.setActiveGrade('el');
      }
    }
  }, [_vm._v(" 小学校 ")]), _c('button', {
    staticClass: "switch_button__item",
    class: {
      active: _vm.activeGrade === 'jh'
    },
    attrs: {
      "disabled": !_setup.isHaveSchoolType.jh
    },
    on: {
      "click": function click($event) {
        return _setup.setActiveGrade('jh');
      }
    }
  }, [_vm._v(" 中学校 ")]), _c('button', {
    staticClass: "switch_button__item",
    class: {
      active: _vm.activeGrade === 'hs'
    },
    attrs: {
      "disabled": !_setup.isHaveSchoolType.hs
    },
    on: {
      "click": function click($event) {
        return _setup.setActiveGrade('hs');
      }
    }
  }, [_vm._v(" 高等学校 ")]), _c('button', {
    staticClass: "switch_button__item",
    class: {
      active: _vm.activeGrade === 'ss'
    },
    attrs: {
      "disabled": !_setup.isHaveSchoolType.ss
    },
    on: {
      "click": function click($event) {
        return _setup.setActiveGrade('ss');
      }
    }
  }, [_vm._v(" 特別支援 ")])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };