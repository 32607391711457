import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";

import { useVModel } from '@vueuse/core';
import { computed } from 'vue';
export default {
  __name: 'ForumSelectDetailSchoolsSchool',
  props: {
    value: {
      /** @type {PropType<number[]>} */
      type: Array,
      required: true
    },
    school: {
      /**
       * @type {PropType<{
       *   id: number;
       *   short_name: string | null;
       *   name: string;
       *   grade: { id: number; school_id: number; name: string }[];
       * }>}
       */
      type: Object,
      required: true
    },
    isShowChecked: {
      type: Boolean,
      required: true
    },
    searchWord: {
      type: String,
      required: true
    }
  },
  emits: ["input"],
  setup: function setup(__props, _ref) {
    var expose = _ref.expose,
      emit = _ref.emit;
    var props = __props;

    // @ts-check

    var tempSelectIdList = useVModel(props, "value", emit, {
      eventName: "input"
    });

    /** 表示する学校リストを作成する */
    var showGradeList = computed(function () {
      if (props.isShowChecked && props.searchWord) {
        return props.school.grade.filter(function (v) {
          return tempSelectIdList.value.includes(v.id) && v.name.includes(props.searchWord);
        });
      } else if (props.isShowChecked) {
        return props.school.grade.filter(function (v) {
          return tempSelectIdList.value.includes(v.id);
        });
      } else if (props.searchWord) {
        return props.school.grade.filter(function (v) {
          return v.name.includes(props.searchWord);
        });
      } else {
        return props.school.grade;
      }
    });
    var isAllGradeSelect = function isAllGradeSelect() {
      return showGradeList.value.every(function (v) {
        return tempSelectIdList.value.includes(v.id);
      });
    };
    var getShowGradeIdList = function getShowGradeIdList() {
      return showGradeList.value.map(function (v) {
        return v.id;
      });
    };
    expose({
      isAllGradeSelect: isAllGradeSelect,
      getShowGradeIdList: getShowGradeIdList
    });
    return {
      __sfc: true,
      props: props,
      emit: emit,
      tempSelectIdList: tempSelectIdList,
      showGradeList: showGradeList,
      isAllGradeSelect: isAllGradeSelect,
      getShowGradeIdList: getShowGradeIdList
    };
  }
};