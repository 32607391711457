export default {
  __name: 'MessageModal',
  props: {
    okButton: {
      type: Boolean,
      default: true
    }
  },
  emits: ["close"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    // @ts-check

    return {
      __sfc: true,
      emit: emit
    };
  }
};