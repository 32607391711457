import _slicedToArray from "/codebuild/output/src4131501249/src/cocoo_gov/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.map.js";

import { inject, ref, computed } from 'vue';
import { allSchoolListKey, isHaveSchoolTypeKey } from '/codebuild/output/src4131501249/src/cocoo_gov/src/utils/injectionKey.js';
import { useToggle } from '@vueuse/core';
export default {
  __name: 'ForumSelectSchools',
  props: {
    selectSchoolIdList: {
      /** @type {PropType<number[]>} */
      type: Array,
      required: true
    },
    target: {
      type: String,
      required: true
    }
  },
  emits: ["handleClickNext", "updeteSchoolIds", "toggleIsShowModal"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;

    // @ts-check

    var allSchoolList = inject(allSchoolListKey);
    var isHaveSchoolType = inject(isHaveSchoolTypeKey);
    var selectSchoolType = ref("");
    var _useToggle = useToggle(false),
      _useToggle2 = _slicedToArray(_useToggle, 2),
      isShowChecked = _useToggle2[0],
      toggleIsShowChecked = _useToggle2[1];

    /** 表示する学校リストを作成する */
    var showSchoolList = computed(function () {
      if (isShowChecked.value && selectSchoolType.value) {
        return allSchoolList.value.filter(function (v) {
          return v.type === selectSchoolType.value && tempSelectIdList.value.includes(v.id);
        });
      } else if (isShowChecked.value) {
        return allSchoolList.value.filter(function (v) {
          return tempSelectIdList.value.includes(v.id);
        });
      } else if (selectSchoolType.value) {
        return allSchoolList.value.filter(function (v) {
          return v.type === selectSchoolType.value;
        });
      } else {
        return allSchoolList.value;
      }
    });
    var tempSelectIdList = ref(props.selectSchoolIdList);
    var isAllSelect = computed(function () {
      return showSchoolList.value.every(function (v) {
        return tempSelectIdList.value.includes(v.id);
      });
    });
    var handleAllSelect = function handleAllSelect() {
      if (isAllSelect.value) {
        tempSelectIdList.value = [];
      } else {
        tempSelectIdList.value = showSchoolList.value.map(function (v) {
          return v.id;
        });
      }
    };
    var handleClickNext = function handleClickNext() {
      emit("handleClickNext", tempSelectIdList.value);
    };
    return {
      __sfc: true,
      props: props,
      emit: emit,
      allSchoolList: allSchoolList,
      isHaveSchoolType: isHaveSchoolType,
      selectSchoolType: selectSchoolType,
      isShowChecked: isShowChecked,
      toggleIsShowChecked: toggleIsShowChecked,
      showSchoolList: showSchoolList,
      tempSelectIdList: tempSelectIdList,
      isAllSelect: isAllSelect,
      handleAllSelect: handleAllSelect,
      handleClickNext: handleClickNext
    };
  }
};