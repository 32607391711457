import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('main', {
    staticClass: "main page_dashboard"
  }, [_c('SchoolTypeSwitch', {
    attrs: {
      "active-grade": _vm.activeGrade
    },
    on: {
      "setActiveGrade": _vm.setActiveGrade
    }
  }), _c('div', {
    staticClass: "select-update"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedDay,
      expression: "selectedDay"
    }],
    staticClass: "select",
    attrs: {
      "type": "date"
    },
    domProps: {
      "value": _vm.selectedDay
    },
    on: {
      "change": _vm.changeDate,
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.selectedDay = $event.target.value;
      }
    }
  }), _c('p', {
    staticClass: "update"
  }, [_c('span', {
    staticClass: "date"
  }, [_vm._v(_vm._s(_vm.currentDate))]), _c('span', {
    staticClass: "update"
  }, [_vm._v(_vm._s(_vm.updatedAt) + "更新")])])]), _c('div', {
    staticClass: "top_section"
  }, [_c('div', {
    staticClass: "status_box"
  }, [_c('dl', {
    staticClass: "list__dl"
  }, [_c('div', {
    staticClass: "list__item"
  }, [_c('dt', {
    staticClass: "list__item__heading"
  }, [_vm._v("全学校数")]), _c('dd', {
    staticClass: "list__item__text"
  }, [_c('span', {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm.schools.length))]), _c('span', {
    staticClass: "text"
  }, [_vm._v("校")])])]), _c('div', {
    staticClass: "list__item"
  }, [_c('dt', {
    staticClass: "list__item__heading"
  }, [_vm._v("全生徒数")]), _c('dd', {
    staticClass: "list__item__text"
  }, [_c('span', {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm._f("addComma")(_vm.totalStudents)))]), _c('span', {
    staticClass: "text"
  }, [_vm._v("名")])])]), _c('div', {
    staticClass: "list__item"
  }, [_c('dt', {
    staticClass: "list__item__heading"
  }, [_vm._v("欠席者数")]), _c('dd', {
    staticClass: "list__item__text"
  }, [_c('span', {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm._f("addComma")(_vm.totalAbsence)))]), _c('span', {
    staticClass: "text"
  }, [_vm._v("名(" + _vm._s((_vm.totalAbsence * 100 / _vm.totalStudents).toFixed(1)) + "%)")])])]), _c('div', {}, [_c('p', [_c('span', {}, [_vm._v(" 内) 感染症 ")]), _c('span', {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm._f("addComma")(_vm.totalInfection)))]), _c('span', {
    staticClass: "text"
  }, [_vm._v("名(" + _vm._s((_vm.totalInfection * 100 / _vm.totalStudents).toFixed(1)) + "%)")])])])]), _c(_setup.Inc137)], 1), _c('div', {
    staticClass: "condition_box"
  }, [_c(_setup.PieChart, {
    attrs: {
      "pie-chart-data": _vm.pieChartData,
      "height": 400
    }
  }), _c(_setup.Inc137)], 1), _c('ForumDashboard'), _c('div', {
    staticClass: "graph_box"
  }, [_c('div', {
    staticClass: "graph_box__header"
  }, [_c('h3', [_vm._v("欠席者総数の変化")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.barMonth,
      expression: "barMonth"
    }],
    attrs: {
      "id": "selectBarMonth"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.barMonth = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, _vm.barMonthChange]
    }
  }, [_c('option', {
    attrs: {
      "value": "current"
    }
  }, [_vm._v("直近31日")]), _vm._l(_vm.monthList, function (month, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": month.period
      }
    }, [_vm._v(" " + _vm._s(month.month) + "月 ")]);
  })], 2)]), _c(_setup.Bar, {
    attrs: {
      "bar-data": _vm.barData,
      "active-grade": _vm.activeGrade,
      "width": 280,
      "height": 190,
      "child-id": "barChart1"
    }
  }), _c(_setup.Inc137)], 1), _c('div', {
    staticClass: "bar"
  }, [_c('h3', {
    style: {
      textAlign: 'center'
    }
  }, [_vm._v("学校別欠席者数")]), _c(_setup.BarChartRace, {
    attrs: {
      "bar-chart-race-data": _vm.barChartRaceData
    }
  }), _c(_setup.Inc137)], 1), _c('div', {
    staticClass: "map"
  }, [_c('h3', {
    style: {
      textAlign: 'center'
    }
  }, [_vm._v("欠席状況")]), _c(_setup.PointsMap, {
    attrs: {
      "height": "580px",
      "is-show-menu": true,
      "active-grade": _vm.activeGrade,
      "selected-day": _vm.selectedDay,
      "current-obj": _vm.currentObj
    }
  }), _c(_setup.Inc137, {
    attrs: {
      "bottom-val": 40
    }
  })], 1)], 1), _c('div', {
    staticClass: "table"
  }, [_c('div', {
    staticClass: "table__header"
  }, [_c('p', {
    staticClass: "table__heading"
  }, [_vm._v(" " + _vm._s(_setup.me.government_name) + " 欠席連絡一覧"), _c('br'), _vm._v(" " + _vm._s(_vm.current) + "現在 ")])]), _c(_setup.TableRow, {
    attrs: {
      "all-schools-table": _vm.allSchoolsTable
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };