import { ref } from 'vue';
export default {
  __name: 'ForumSelect',
  props: {
    target: {
      type: String,
      required: true
    },
    selectSchoolIdList: {
      /** @type {PropType<number[]>} */
      type: Array,
      required: true
    },
    selectGradeIdList: {
      /** @type {PropType<number[]>} */
      type: Array,
      required: true
    }
  },
  emits: ["updeteSchoolIds", "updeteGradeIds", "toggleIsShowModal"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;

    // @ts-check

    var tempSelectIdList = ref(props.selectSchoolIdList);
    var isSchoolSelect = ref(true);
    var handleClickNext = function handleClickNext(schoolIdList) {
      var gradeIdList = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      if (props.target === "school" || !isSchoolSelect.value) {
        // 学校掲示板or学年を選んだ後
        emit("updeteSchoolIds", schoolIdList);
        if (props.target === "guardian") {
          emit("updeteGradeIds", gradeIdList);
        }
        emit("toggleIsShowModal", false);
      } else {
        tempSelectIdList.value = schoolIdList;
        isSchoolSelect.value = false;
      }
    };
    var toggleIsShowModal = function toggleIsShowModal(input) {
      emit("toggleIsShowModal", input);
    };
    return {
      __sfc: true,
      props: props,
      emit: emit,
      tempSelectIdList: tempSelectIdList,
      isSchoolSelect: isSchoolSelect,
      handleClickNext: handleClickNext,
      toggleIsShowModal: toggleIsShowModal
    };
  }
};