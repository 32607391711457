import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import * as d3 from "d3";
export default {
  props: {
    barData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    activeGrade: {
      type: String,
      default: function _default() {
        return "all";
      }
    },
    width: {
      type: Number,
      default: function _default() {
        return 280;
      }
    },
    height: {
      type: Number,
      default: function _default() {
        return 270;
      }
    },
    childId: {
      type: String,
      default: function _default() {
        return "barChart1";
      }
    }
  },
  watch: {
    barData: function barData() {
      this.bar();
    },
    activeGrade: function activeGrade() {
      this.bar();
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    bar: function bar() {
      var _this = this;
      var parentDiv = d3.select("#".concat(this.childId));
      d3.select("#".concat(this.childId, "Svg")).remove();
      var width = this.width;
      var height = this.height;
      var dataset = this.barData.map(function (v) {
        var date = new Date(v.date);
        var date_string = date.getMonth() + 1 + "/" + date.getDate();
        return {
          name: date_string,
          value: v[_this.activeGrade]
        };
      });
      var xPadding = 40; // スケール表示用マージン
      var yPadding = 60;
      var svg = parentDiv.append("svg").attr("id", "".concat(this.childId, "Svg")).attr("width", width).attr("height", height);
      var xScale = d3.scaleBand().rangeRound([xPadding, width - xPadding]).padding(0.1).domain(dataset.map(function (d) {
        return d.name;
      }));
      var yScale = d3.scaleLinear().domain([0, d3.max(dataset, function (d) {
        return d.value;
      })]).range([height - yPadding, 10]);
      svg.append("g").attr("transform", "translate(" + 0 + "," + (height - yPadding) + ")").call(d3.axisBottom(xScale).tickValues(xScale.domain().filter(function (d, i) {
        return !(i % 7);
      }))).style("font-size", 12).selectAll("text");
      svg.append("g").attr("transform", "translate(" + xPadding + "," + 0 + ")").call(d3.axisLeft(yScale));
      svg.append("g").selectAll("rect").data(dataset).enter().append("rect").attr("x", function (d) {
        return xScale(d.name);
      }).attr("y", function (d) {
        return yScale(d.value);
      }).attr("width", xScale.bandwidth()).attr("height", function (d) {
        return height - yPadding - yScale(d.value);
      }).attr("fill", "#0064B5");
    }
  }
};