import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.type === 'school' ? _c('span', {
    staticClass: "icon school",
    class: _vm.$route.name === 'Dashboard' ? 'small' : ''
  }, [_vm._v(" " + _vm._s(_vm.$route.name === "Dashboard" ? "先" : "先生") + " ")]) : _vm.type === 'guardian' ? _c('span', {
    staticClass: "icon guardian",
    class: _vm.$route.name === 'Dashboard' ? 'small' : ''
  }, [_vm._v(" " + _vm._s(_vm.$route.name === "Dashboard" ? "保" : "保護者") + " ")]) : _vm.type === 'important' ? _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 512 512"
    }
  }, [_c('path', {
    attrs: {
      "d": "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z",
      "fill": "#AB001C"
    }
  })]) : _vm.type === 'update' ? _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  }, [_c('path', {
    attrs: {
      "id": "update_FILL0_wght400_GRAD0_opsz48",
      "d": "M129.075-822a8.8,8.8,0,0,1-3.525-.713,9.3,9.3,0,0,1-2.887-1.937,9.048,9.048,0,0,1-1.95-2.875A8.8,8.8,0,0,1,120-831.05a8.619,8.619,0,0,1,.713-3.5,8.978,8.978,0,0,1,1.95-2.837,9.237,9.237,0,0,1,2.887-1.912,8.931,8.931,0,0,1,3.525-.7,8.5,8.5,0,0,1,3.787.875,9.514,9.514,0,0,1,3.088,2.4v-2.65h1.5v5.2h-5.225v-1.5h2.625a8.694,8.694,0,0,0-2.587-2.05,6.8,6.8,0,0,0-3.188-.775,7.447,7.447,0,0,0-5.35,2.137,6.982,6.982,0,0,0-2.225,5.238,7.4,7.4,0,0,0,2.2,5.4,7.271,7.271,0,0,0,5.375,2.225,7.09,7.09,0,0,0,5.275-2.2,7.339,7.339,0,0,0,2.15-5.325H138a8.764,8.764,0,0,1-2.6,6.387A8.548,8.548,0,0,1,129.075-822Zm3.05-4.925-3.85-3.8v-5.35h1.5v4.725L133.2-828Z",
      "transform": "translate(-120 840)",
      "fill": "#464646"
    }
  })]) : _vm.type === 'mail' ? _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 -960 960 960",
      "width": "20px",
      "height": "20px",
      "fill": "#464646"
    }
  }, [_c('path', {
    attrs: {
      "d": "M172.31-180Q142-180 121-201q-21-21-21-51.31v-455.38Q100-738 121-759q21-21 51.31-21h615.38Q818-780 839-759q21 21 21 51.31v455.38Q860-222 839-201q-21 21-51.31 21H172.31ZM480-457.69 160-662.31v410q0 5.39 3.46 8.85t8.85 3.46h615.38q5.39 0 8.85-3.46t3.46-8.85v-410L480-457.69Zm0-62.31 313.85-200h-627.7L480-520ZM160-662.31V-720v467.69q0 5.39 3.46 8.85t8.85 3.46H160v-422.31Z"
    }
  })]) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };