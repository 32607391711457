import { dashboardTableList } from "@/lib/dashboardTableList";
export default {
  name: "TableRow",
  filters: {
    zeroFallback: function zeroFallback(val) {
      return val ? val : 0;
    }
  },
  props: {
    allSchoolsTable: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      dashboardTableList: dashboardTableList
    };
  },
  methods: {
    decimalAlignmentPercent: function decimalAlignmentPercent(count, studentsCount) {
      return count ? "(" + Math.round(count / studentsCount * 1000) / 10 + "%)" : "";
    }
  }
};