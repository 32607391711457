import "core-js/modules/es.function.name.js";
var render = function render() {
  var _setup$me, _setup$me$allow;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('main', {
    staticClass: "main page_class_index page_school_staff"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._v("登録職員一覧")]), _c('form', {
    staticClass: "gray_frame",
    attrs: {
      "action": "",
      "method": "get"
    }
  }, [_c('row-item', [_c('div', {
    staticClass: "col_10"
  }, [_c('row-item', [_c('label', {
    staticClass: "col_3",
    attrs: {
      "for": "filter_keyword"
    }
  }, [_vm._v("職員氏名")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filterKeyword,
      expression: "filterKeyword"
    }],
    staticClass: "common_form--input col_8",
    attrs: {
      "id": "filter_keyword",
      "type": "text"
    },
    domProps: {
      "value": _vm.filterKeyword
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.filterKeyword = $event.target.value;
      }
    }
  })])], 1), _c('div', {
    staticClass: "col_2 tar"
  }, [_c('input', {
    staticClass: "search_button",
    attrs: {
      "type": "submit",
      "value": "検索"
    },
    on: {
      "click": _vm.filterData
    }
  })])])], 1), (_setup$me = _setup.me) !== null && _setup$me !== void 0 && (_setup$me$allow = _setup$me.allow) !== null && _setup$me$allow !== void 0 && _setup$me$allow.admin ? _c('section', {
    staticClass: "tac"
  }, [_c('router-link', {
    staticClass: "create_button",
    attrs: {
      "to": {
        name: 'StaffsCreate'
      }
    }
  }, [_vm._v(" 新規登録 ")])], 1) : _vm._e(), _vm.visibleStaffs.length === 0 && !_vm.isLoading.staffs ? _c('section', [_vm._v(" 該当する職員が見つかりません ")]) : _c('div', {
    staticClass: "staffs_table"
  }, [_c('row-item', {
    staticClass: "staffs_table__head"
  }, [_vm.isLoading.staffs ? _c('p', {
    staticClass: "col_12"
  }, [_vm._v("職員情報を取得しています")]) : [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("職員氏名")]), _c('p', {
    staticClass: "col_3"
  }, [_vm._v("部署")]), _c('p', {
    staticClass: "col_3"
  }, [_vm._v("管理者権限")]), _c('p', {
    staticClass: "col_3"
  }, [_vm._v("掲示板投稿")])]], 2), _vm.isLoading.staffs ? _c('content-placeholders', {
    staticClass: "staffs_table__body"
  }, _vm._l(5, function (n) {
    return _c('row-item', {
      key: n
    }, [_c('p', {
      staticClass: "col_3"
    }, [_c('content-placeholders-text', {
      attrs: {
        "lines": 1
      }
    })], 1), _c('div', {
      staticClass: "col_3"
    }, [_c('content-placeholders-text', {
      attrs: {
        "lines": 1
      }
    })], 1), _c('div', {
      staticClass: "col_3"
    }, [_c('content-placeholders-text', {
      attrs: {
        "lines": 1
      }
    })], 1), _c('div', {
      staticClass: "col_2"
    }, [_c('content-placeholders-text', {
      attrs: {
        "lines": 1
      }
    })], 1), _c('div', {
      staticClass: "col_1"
    }, [_c('content-placeholders-text', {
      attrs: {
        "lines": 1
      }
    })], 1)]);
  }), 1) : _c('div', {
    staticClass: "staffs_table__body"
  }, _vm._l(_vm.visibleStaffs, function (staff, index) {
    var _setup$me2, _setup$me2$allow;
    return _c('div', {
      key: index
    }, [_c('row-item', [_c('p', {
      staticClass: "col_3"
    }, [_vm._v(" " + _vm._s(staff.name) + " ")]), _c('p', {
      staticClass: "col_3"
    }, [_vm._v(" " + _vm._s(staff.department) + " ")]), _c('p', {
      staticClass: "col_3"
    }, [_vm._v(" " + _vm._s(staff.allow && staff.allow.admin ? "◯" : "") + " ")]), _c('p', {
      staticClass: "col_2"
    }, [_vm._v(" " + _vm._s(staff.allow && staff.allow.forum_post ? "◯" : "") + " ")]), _c('div', {
      staticClass: "col_1 tar"
    }, [(_setup$me2 = _setup.me) !== null && _setup$me2 !== void 0 && (_setup$me2$allow = _setup$me2.allow) !== null && _setup$me2$allow !== void 0 && _setup$me2$allow.admin ? _c('router-link', {
      staticClass: "edit_button",
      attrs: {
        "to": {
          name: 'StaffsEdit',
          params: {
            id: staff.id
          }
        }
      }
    }, [_vm._v(" 編集 ")]) : _vm._e()], 1)])], 1);
  }), 0)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };