import "core-js/modules/es.array.map.js";
import { cdate } from "@/utils/cdate";

import { computed } from 'vue';
export default {
  __name: 'ForumItem',
  props: {
    forum: {
      type: Object,
      required: true
    }
  },
  setup: function setup(__props) {
    var props = __props;

    // @ts-check

    var selectSchoolIdList = computed(function () {
      return props.forum.school.map(function (v) {
        return v.id;
      });
    });
    return {
      __sfc: true,
      props: props,
      selectSchoolIdList: selectSchoolIdList,
      cdate: cdate
    };
  }
};