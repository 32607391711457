import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _setup$me$allow, _setup$me, _setup$me$allow2, _setup$me$allow3, _setup$me2, _setup$me2$allow, _setup$me$allow4, _setup$me3, _setup$me3$allow, _setup$me$allow5, _setup$me4, _setup$me4$allow, _setup$me$allow6, _setup$me5, _setup$me5$allow, _setup$me$allow7, _setup$me6, _setup$me6$allow, _setup$data$staff;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('main', {
    staticClass: "main"
  }, [_c('div', {
    staticClass: "page-header"
  }, [_c('h2', {
    staticClass: "page-header__title"
  }, [_vm._v("記事詳細")]), _c('router-link', {
    staticClass: "page-header__button small",
    attrs: {
      "to": {
        name: 'ForumIndex'
      }
    }
  }, [_vm._v(" 一覧に戻る ")])], 1), _setup.data ? _c('div', {
    staticClass: "post"
  }, [_c('div', {
    staticClass: "post__header"
  }, [_setup.data.is_important || _setup.data.created_at !== _setup.data.updated_at ? _c('div', {
    staticClass: "post__header__icons"
  }, [_setup.data.is_important ? _c('TextIcon', {
    attrs: {
      "type": "important"
    }
  }) : _vm._e(), _setup.data.email_status === 'not_sent' && _setup.data.created_at !== _setup.data.updated_at ? _c('TextIcon', {
    attrs: {
      "type": "update"
    }
  }) : _vm._e()], 1) : _vm._e(), _c('h3', {
    staticClass: "post__header__text"
  }, [_vm._v(_vm._s(_setup.data.title))]), _setup.data.attachment.length ? _c('div', {
    staticClass: "post__header__attached"
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  }, [_c('title', [_vm._v("添付ファイル")]), _c('path', {
    attrs: {
      "d": "M16.5,6V17.5A4,4 0 0,1 12.5,21.5A4,4 0 0,1 8.5,17.5V5A2.5,2.5 0 0,1 11,2.5A2.5,2.5 0 0,1 13.5,5V15.5A1,1 0 0,1 12.5,16.5A1,1 0 0,1 11.5,15.5V6H10V15.5A2.5,2.5 0 0,0 12.5,18A2.5,2.5 0 0,0 15,15.5V5A4,4 0 0,0 11,1A4,4 0 0,0 7,5V17.5A5.5,5.5 0 0,0 12.5,23A5.5,5.5 0 0,0 18,17.5V6H16.5Z"
    }
  })])]) : _vm._e(), _c('div', {
    staticClass: "tool"
  }, [_setup.data.email_status === 'not_sent' && ((_setup$me$allow = _setup.me.allow) !== null && _setup$me$allow !== void 0 && _setup$me$allow.admin && (_setup$me = _setup.me) !== null && _setup$me !== void 0 && (_setup$me$allow2 = _setup$me.allow) !== null && _setup$me$allow2 !== void 0 && _setup$me$allow2.forum_post || !((_setup$me$allow3 = _setup.me.allow) !== null && _setup$me$allow3 !== void 0 && _setup$me$allow3.admin) && (_setup$me2 = _setup.me) !== null && _setup$me2 !== void 0 && (_setup$me2$allow = _setup$me2.allow) !== null && _setup$me2$allow !== void 0 && _setup$me2$allow.forum_post && _setup.data.staff_id === _setup.me.id) ? _c('button', {
    staticClass: "edit-button",
    on: {
      "click": function click($event) {
        return _setup.router.push({
          name: 'ForumEdit'
        });
      }
    }
  }, [_vm._v(" 編集 ")]) : _vm._e(), (_setup$me$allow4 = _setup.me.allow) !== null && _setup$me$allow4 !== void 0 && _setup$me$allow4.admin && (_setup$me3 = _setup.me) !== null && _setup$me3 !== void 0 && (_setup$me3$allow = _setup$me3.allow) !== null && _setup$me3$allow !== void 0 && _setup$me3$allow.forum_post || !((_setup$me$allow5 = _setup.me.allow) !== null && _setup$me$allow5 !== void 0 && _setup$me$allow5.admin) && (_setup$me4 = _setup.me) !== null && _setup$me4 !== void 0 && (_setup$me4$allow = _setup$me4.allow) !== null && _setup$me4$allow !== void 0 && _setup$me4$allow.forum_post && _setup.data.staff_id === _setup.me.id ? _c('button', {
    staticClass: "edit-button",
    on: {
      "click": function click($event) {
        return _setup.router.push({
          name: 'ForumCreate',
          params: _setup.dataForCopy
        });
      }
    }
  }, [_vm._v(" 複製 ")]) : _vm._e(), _setup.data.email_status === 'not_sent' && ((_setup$me$allow6 = _setup.me.allow) !== null && _setup$me$allow6 !== void 0 && _setup$me$allow6.admin && (_setup$me5 = _setup.me) !== null && _setup$me5 !== void 0 && (_setup$me5$allow = _setup$me5.allow) !== null && _setup$me5$allow !== void 0 && _setup$me5$allow.forum_post || !((_setup$me$allow7 = _setup.me.allow) !== null && _setup$me$allow7 !== void 0 && _setup$me$allow7.admin) && (_setup$me6 = _setup.me) !== null && _setup$me6 !== void 0 && (_setup$me6$allow = _setup$me6.allow) !== null && _setup$me6$allow !== void 0 && _setup$me6$allow.forum_post && _setup.data.staff_id === _setup.me.id) ? _c('button', {
    staticClass: "delete-button",
    on: {
      "click": function click($event) {
        return _setup.toggleIsShowDeleteConfirmModal();
      }
    }
  }, [_vm._v(" 削除 ")]) : _vm._e()])]), _c('div', {
    staticClass: "post__data"
  }, [_c('time', {
    staticClass: "date",
    attrs: {
      "datetime": _setup.data.published_at
    }
  }, [_vm._v(" " + _vm._s(_setup.cdate(_setup.data.published_at).format("YYYY年MM月DD日 HH時mm分")) + " ")]), _c('p', {
    staticClass: "author"
  }, [_vm._v(_vm._s(_setup.data.author_name))]), _c('p', {
    staticClass: "position"
  }, [_vm._v(_vm._s((_setup$data$staff = _setup.data.staff) === null || _setup$data$staff === void 0 ? void 0 : _setup$data$staff.name))]), _c('ForumSchoolCount', {
    attrs: {
      "select-school-id-list": _setup.selectSchoolIdList
    }
  }), _setup.data.email_status === 'sent' ? _c('p', [_vm._v(" 送信数: " + _vm._s(_setup.data.guardian_student_count) + " ")]) : _vm._e()], 1), _c('div', {
    staticClass: "post__period"
  }, [_c('p', {
    staticClass: "post__period__label"
  }, [_vm._v("表示期間：")]), _c('time', {
    staticClass: "post__period__date",
    attrs: {
      "datetime": _setup.cdate(_setup.data.published_at).format('YYYY-MM-DD')
    }
  }, [_vm._v(_vm._s(_setup.cdate(_setup.data.published_at).format("YYYY年MM月DD日(ddd)")))]), _c('span', [_vm._v("〜")]), _c('time', {
    staticClass: "post__period__date",
    attrs: {
      "datetime": _setup.cdate(_setup.data.expire_on).format('YYYY-MM-DD')
    }
  }, [_vm._v(_vm._s(_setup.cdate(_setup.data.expire_on).format("YYYY年MM月DD日(ddd)")))])]), _c('div', {
    staticClass: "post__content"
  }, [_setup.data.message ? _vm._l(_setup.data.message.split('\n'), function (text, i) {
    return _c('span', {
      key: "".concat(i, "-").concat(text),
      style: {
        'white-space': 'pre-line'
      }
    }, [_c('UrlToLink', {
      attrs: {
        "text": text
      }
    }), _vm._v(_vm._s("\n") + " ")], 1);
  }) : _vm._e(), _setup.data.attachment.length ? _c('div', {
    staticClass: "post__content__attached"
  }, _vm._l(_setup.data.attachment, function (attachment) {
    return _c('ForumAttachmentItem', {
      key: attachment.id,
      attrs: {
        "attachment": attachment
      }
    });
  }), 1) : _vm._e()], 2)]) : _vm._e(), _c('section', {
    staticClass: "other-forum"
  }, [_vm._m(0), _c('div', {
    staticClass: "other-forum__wrapper"
  }, _vm._l(_setup.forumList, function (forum) {
    return _c('ForumItem', {
      key: forum.id,
      attrs: {
        "forum": forum
      }
    });
  }), 1)]), _setup.isShowDeleteConfirmModal ? _c('ConfirmModal', {
    on: {
      "clickOK": _setup.deleteForum,
      "clickCancel": function clickCancel($event) {
        return _setup.toggleIsShowDeleteConfirmModal();
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v("記事削除")];
      },
      proxy: true
    }, {
      key: "message",
      fn: function fn() {
        return [_c('p', [_vm._v("記事を削除します。")]), _c('p', [_vm._v("本当に実行してもよろしいですか？")])];
      },
      proxy: true
    }], null, false, 2199151982)
  }) : _vm._e()], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "other-forum__header"
  }, [_c('h3', {
    staticClass: "other-forum__title"
  }, [_vm._v("その他の記事一覧")])]);
}];
export { render, staticRenderFns };