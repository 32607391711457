import "core-js/modules/es.function.name.js";
var render = function render() {
  var _setup$me, _setup$me$government_, _setup$me2, _setup$me2$allow, _setup$me3, _setup$me3$government;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('header', {
    staticClass: "header"
  }, [_c('section', {
    staticClass: "header__inner common_width"
  }, [_c('h1', {
    staticClass: "header__title"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'Dashboard'
      }
    }
  }, [_vm._v(" " + _vm._s(_setup.me.government_name))])], 1), _vm.$auth.isAuthenticated ? _c('nav', {
    staticClass: "global_navigation"
  }, [_c('p', {
    staticClass: "me"
  }, [_vm._v(_vm._s(_setup.me.name) + "としてログイン中")]), (_setup$me = _setup.me) !== null && _setup$me !== void 0 && (_setup$me$government_ = _setup$me.government_plan_allow) !== null && _setup$me$government_ !== void 0 && _setup$me$government_.forum ? _c('details', {
    staticClass: "pd-menu"
  }, [_c('summary', {
    staticClass: "pd-menu__summary"
  }, [_vm._v("掲示板/メール")]), _c('nav', {
    staticClass: "pd-menu__navigation"
  }, [(_setup$me2 = _setup.me) !== null && _setup$me2 !== void 0 && (_setup$me2$allow = _setup$me2.allow) !== null && _setup$me2$allow !== void 0 && _setup$me2$allow.forum_post ? _c('router-link', {
    attrs: {
      "to": {
        name: 'ForumCreate'
      }
    }
  }, [_vm._v(" 掲示板記事作成 ")]) : _vm._e(), _c('router-link', {
    attrs: {
      "to": {
        name: 'ForumIndex'
      }
    }
  }, [_vm._v("掲示板一覧")]), (_setup$me3 = _setup.me) !== null && _setup$me3 !== void 0 && (_setup$me3$government = _setup$me3.government_plan_allow) !== null && _setup$me3$government !== void 0 && _setup$me3$government.forum_email ? _c('router-link', {
    attrs: {
      "to": {
        name: 'ForumMailIndex'
      }
    }
  }, [_vm._v(" メール配信一覧 ")]) : _vm._e()], 1)]) : _vm._e(), _c('router-link', {
    staticClass: "link",
    attrs: {
      "to": {
        name: 'Staffs'
      }
    }
  }, [_vm._v(" 職員一覧 ")]), _c('router-link', {
    staticClass: "link",
    attrs: {
      "to": {
        name: 'LogsLogin'
      }
    }
  }, [_vm._v(" ログイン履歴 ")]), _c('a', {
    staticClass: "button button--small button--no_shadow",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.handleLogout.apply(null, arguments);
      }
    }
  }, [_vm._v(" ログアウト ")])], 1) : _vm._e()])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };