var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('main', {
    staticClass: "main page_login"
  }, [_vm.isForceLogout ? _c('div', {
    staticClass: "message_box"
  }, [_vm._v(" " + _vm._s(_vm.ttlJP) + "間操作が行われなかった為、自動ログアウトしました。 ")]) : _vm._e(), !_vm.$auth.isAuthenticated ? _c('div', {
    staticClass: "login_box"
  }, [_c('a', {
    staticClass: "login_button",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.handleLogin.apply(null, arguments);
      }
    }
  }, [_vm._v("ログイン")])]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };