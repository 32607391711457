export default {
  __name: 'TextIcon',
  props: {
    type: {
      type: String,
      required: true
    }
  },
  setup: function setup(__props) {
    // @ts-check

    return {
      __sfc: true
    };
  }
};