import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts
  },
  props: {
    pieChartData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    width: {
      type: Number,
      default: function _default() {
        return 280;
      }
    },
    height: {
      type: Number,
      default: function _default() {
        return 270;
      }
    },
    legendPosition: {
      type: String,
      default: function _default() {
        return "bottom";
      }
    }
  },
  data: function data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          height: this.height,
          width: this.width,
          type: "pie"
        }
      }
    };
  },
  watch: {
    pieChartData: function pieChartData() {
      var _this = this;
      var colorList = {
        風邪: "#008FFB",
        発熱: "#00E396",
        頭痛: "#FEB019",
        腹痛: "#FF4560",
        嘔吐下痢: "#775DD0",
        "咳・息苦しさ": "#3F51B5",
        "受診・通院": "#03A9F4",
        発疹: "#4CAF50",
        ケガ: "#F9CE1D",
        その他病気: "#FF9800",
        体調不良: "#33B2DF",
        コロナ: "#546E7A",
        感染症: "#D4526E",
        "介護・手伝い": "#13D8AA",
        忌引: "#A5978B",
        特支: "#4ECDC4",
        家の都合: "#C7F464",
        その他: "#81D4FA"
      };
      this.series = [];
      var labels = [];
      var colors = [];
      this.pieChartData.forEach(function (v) {
        _this.series.push(v.value);
        colors.push(colorList[v.key]);
        labels.push(v.key);
      });

      // colorsが空の時にエラーになることがあるため対策
      if (!colors.length) {
        colors.push("#D4526E");
      }
      this.chartOptions = {
        chart: {
          height: this.height,
          width: this.width,
          type: "pie"
        },
        labels: labels,
        colors: colors,
        legend: {
          position: this.legendPosition,
          fontSize: "11px"
        }
      };
    }
  }
};