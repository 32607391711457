import "core-js/modules/es.number.constructor.js";
export default {
  props: {
    classList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    totalStudents: {
      type: Number,
      default: function _default() {
        return 0;
      }
    },
    schoolMonthTotal: {
      type: Object,
      default: function _default() {}
    },
    calenderMonth: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      monthAllDays: []
    };
  },
  watch: {
    classList: function classList() {
      this.monthAllDays = [];
      var lastDay = this.calenderMonth.clone().endOf("month");
      for (var d = this.calenderMonth.clone(); d < lastDay; d.add(1, "days")) {
        this.monthAllDays.push({
          class: "week_".concat(d.day()),
          week: d.format("ddd"),
          key: d.format("YYYY-MM-DD")
        });
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {}
};