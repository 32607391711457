import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "table__wrapper"
  }, [_c('table', {
    staticClass: "table__table"
  }, [_c('thead', [_c('tr', [_vm._m(0), _vm._l(_vm.dashboardTableList.firstLineHeader, function (item, i) {
    return _c('th', {
      key: i,
      attrs: {
        "rowspan": item.rowspan,
        "colspan": item.colspan
      }
    }, [_c('p', {
      staticClass: "side"
    }, [_vm._v(_vm._s(item.label))])]);
  })], 2), _c('tr', _vm._l(_vm.dashboardTableList.secondLineHeader, function (item, i) {
    return _c('th', {
      key: i,
      attrs: {
        "rowspan": item.rowspan,
        "colspan": item.colspan
      }
    }, [_c('p', {
      class: {
        side: item.rowspan === 1
      }
    }, [_vm._v(_vm._s(item.label))])]);
  }), 0), _c('tr', _vm._l(_vm.dashboardTableList.thirdLineHeader, function (item, i) {
    return _c('th', {
      key: i
    }, [_c('p', [_vm._v(_vm._s(item.label))])]);
  }), 0)]), _c('tfoot', [_c('tr', [_vm._m(1), _vm._l(_vm.dashboardTableList.firstLineHeader, function (item, i) {
    return _c('th', {
      key: i,
      attrs: {
        "rowspan": item.rowspan,
        "colspan": item.colspan
      }
    }, [_c('p', {
      staticClass: "side"
    }, [_vm._v(_vm._s(item.label))])]);
  })], 2), _c('tr', _vm._l(_vm.dashboardTableList.secondLineHeader, function (item, i) {
    return _c('th', {
      key: i,
      attrs: {
        "rowspan": item.rowspan,
        "colspan": item.colspan
      }
    }, [_c('p', {
      class: {
        side: item.rowspan === 1
      }
    }, [_vm._v(_vm._s(item.label))])]);
  }), 0), _c('tr', _vm._l(_vm.dashboardTableList.thirdLineHeader, function (item, i) {
    return _c('th', {
      key: i
    }, [_c('p', [_vm._v(_vm._s(item.label))])]);
  }), 0)]), _c('tbody', _vm._l(_vm.allSchoolsTable, function (row, index) {
    return _c('tr', {
      key: index,
      class: {
        gray_span: index % 2 !== 0
      }
    }, [row.id === 0 ? _c('td', {
      staticClass: "name",
      style: {
        width: '100px!important'
      }
    }, [_vm._v(" " + _vm._s(row.short_name ? row.short_name : row.name) + " ")]) : _c('td', {
      staticClass: "name",
      style: {
        width: '100px!important'
      }
    }, [_c('router-link', {
      attrs: {
        "to": '/school/' + row.id
      }
    }, [_vm._v(_vm._s(row.short_name ? row.short_name : row.name))])], 1), _c('td', {
      staticClass: "tac"
    }, [_vm._v(" " + _vm._s(_vm._f("addComma")(row.student_count)) + " ")]), _vm._l(_vm.dashboardTableList.key, function (item, i) {
      return _c('td', {
        key: i,
        staticClass: "tac"
      }, [_c('p', [_vm._v(_vm._s(_vm._f("addComma")(_vm._f("zeroFallback")(row.absence[item.label]))))]), item.percent ? _c('p', [_vm._v(" " + _vm._s(_vm.decimalAlignmentPercent(row.absence[item.label], row.student_count)) + " ")]) : _vm._e()]);
    })], 2);
  }), 0)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('th', {
    attrs: {
      "rowspan": "3"
    }
  }, [_c('p', {
    staticClass: "side"
  }, [_vm._v("学校名")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('th', {
    attrs: {
      "rowspan": "3"
    }
  }, [_c('p', {
    staticClass: "side"
  }, [_vm._v("学校名")])]);
}];
export { render, staticRenderFns };