import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm$forum$staff;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('article', {
    staticClass: "forum-item"
  }, [_c('div', {
    staticClass: "forum-item__top"
  }, [_c('time', {
    staticClass: "date",
    attrs: {
      "datetime": _vm.forum.published_at
    }
  }, [_vm._v(" " + _vm._s(_setup.cdate(_setup.props.forum.published_at).format("YYYY年MM月DD日 HH時mm分")) + " ")]), _c('p', {
    staticClass: "position"
  }, [_vm._v(_vm._s(_vm.forum.author_name) + " " + _vm._s((_vm$forum$staff = _vm.forum.staff) === null || _vm$forum$staff === void 0 ? void 0 : _vm$forum$staff.name))]), _c('ForumSchoolCount', {
    attrs: {
      "select-school-id-list": _setup.selectSchoolIdList
    }
  }), _c('router-link', {
    staticClass: "button-detail",
    attrs: {
      "to": {
        name: 'ForumShow',
        params: {
          id: _vm.forum.id
        }
      }
    }
  }, [_vm._v(" 詳細 ")])], 1), _c('div', {
    staticClass: "forum-item__bottom"
  }, [_c('div', {
    staticClass: "icons"
  }, [_vm.forum.target === 'school' ? _c('TextIcon', {
    attrs: {
      "type": "school"
    }
  }) : _vm._e(), _vm.forum.target === 'guardian' ? _c('TextIcon', {
    attrs: {
      "type": "guardian"
    }
  }) : _vm._e(), _vm.forum.is_important ? _c('TextIcon', {
    attrs: {
      "type": "important"
    }
  }) : _vm._e(), _vm.forum.email_status === 'not_sent' && _vm.forum.created_at !== _vm.forum.updated_at ? _c('TextIcon', {
    attrs: {
      "type": "update"
    }
  }) : _vm._e(), _vm.forum.email_status !== 'not_sent' ? _c('TextIcon', {
    attrs: {
      "type": "mail"
    }
  }) : _vm._e()], 1), _c('router-link', {
    staticClass: "link",
    attrs: {
      "to": {
        name: 'ForumShow',
        params: {
          id: _vm.forum.id
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.forum.title) + " ")]), _vm.forum.attachment_count ? _c('div', {
    staticClass: "attached"
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  }, [_c('title', [_vm._v("添付ファイル")]), _c('path', {
    attrs: {
      "d": "M16.5,6V17.5A4,4 0 0,1 12.5,21.5A4,4 0 0,1 8.5,17.5V5A2.5,2.5 0 0,1 11,2.5A2.5,2.5 0 0,1 13.5,5V15.5A1,1 0 0,1 12.5,16.5A1,1 0 0,1 11.5,15.5V6H10V15.5A2.5,2.5 0 0,0 12.5,18A2.5,2.5 0 0,0 15,15.5V5A4,4 0 0,0 11,1A4,4 0 0,0 7,5V17.5A5.5,5.5 0 0,0 12.5,23A5.5,5.5 0 0,0 18,17.5V6H16.5Z"
    }
  })])]) : _vm._e(), _c('p', {
    staticClass: "content"
  }, [_vm._v(" " + _vm._s(_vm.forum.message) + " ")])], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };