import "core-js/modules/es.number.constructor.js";
export default {
  name: "Inc137",
  props: {
    bottomVal: {
      type: Number,
      default: function _default() {
        return 0;
      }
    }
  }
};