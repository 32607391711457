import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.slice.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('fieldset', {
    staticClass: "form__schools__school"
  }, [_c('legend', {
    staticClass: "form__schools__school__heading"
  }, [_vm._v(" " + _vm._s(_vm.school.short_name || _vm.school.name) + " ")]), _vm._l(_setup.showGradeList, function (grade) {
    return _c('label', {
      key: grade.id,
      attrs: {
        "for": "school-grade-".concat(_vm.school.id, "-").concat(grade.id)
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _setup.tempSelectIdList,
        expression: "tempSelectIdList"
      }],
      staticClass: "checkbox",
      attrs: {
        "id": "school-grade-".concat(_vm.school.id, "-").concat(grade.id),
        "type": "checkbox"
      },
      domProps: {
        "value": grade.id,
        "checked": Array.isArray(_setup.tempSelectIdList) ? _vm._i(_setup.tempSelectIdList, grade.id) > -1 : _setup.tempSelectIdList
      },
      on: {
        "change": function change($event) {
          var $$a = _setup.tempSelectIdList,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = grade.id,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && (_setup.tempSelectIdList = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_setup.tempSelectIdList = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _setup.tempSelectIdList = $$c;
          }
        }
      }
    }), _c('span', {
      staticClass: "label"
    }, [_vm._v(_vm._s(grade.name))])]);
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };