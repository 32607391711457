var render = function render() {
  var _setup$me, _setup$me$allow;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('main', {
    staticClass: "main"
  }, [_c('div', {
    staticClass: "page-header"
  }, [_c('h2', {
    staticClass: "page-header__title"
  }, [_vm._v("掲示板")]), _c('div', {
    staticClass: "page-header__select-type",
    attrs: {
      "role": "group"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.payload.target,
      expression: "payload.target"
    }],
    staticClass: "page-header__select-type__radio",
    attrs: {
      "id": "all",
      "type": "radio",
      "name": "type"
    },
    domProps: {
      "checked": _vm._q(_setup.payload.target, null)
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_setup.payload, "target", null);
      }
    }
  }), _c('label', {
    staticClass: "label",
    attrs: {
      "for": "all"
    }
  }, [_vm._v("すべて")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.payload.target,
      expression: "payload.target"
    }],
    staticClass: "page-header__select-type__radio",
    attrs: {
      "id": "school",
      "type": "radio",
      "name": "type",
      "value": "school"
    },
    domProps: {
      "checked": _vm._q(_setup.payload.target, "school")
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_setup.payload, "target", "school");
      }
    }
  }), _c('label', {
    staticClass: "label",
    attrs: {
      "for": "school"
    }
  }, [_vm._v("先生")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.payload.target,
      expression: "payload.target"
    }],
    staticClass: "page-header__select-type__radio",
    attrs: {
      "id": "guardian",
      "type": "radio",
      "name": "type",
      "value": "guardian"
    },
    domProps: {
      "checked": _vm._q(_setup.payload.target, "guardian")
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_setup.payload, "target", "guardian");
      }
    }
  }), _c('label', {
    staticClass: "label",
    attrs: {
      "for": "guardian"
    }
  }, [_vm._v("保護者")])])]), (_setup$me = _setup.me) !== null && _setup$me !== void 0 && (_setup$me$allow = _setup$me.allow) !== null && _setup$me$allow !== void 0 && _setup$me$allow.forum_post ? _c('router-link', {
    staticClass: "edit-button",
    attrs: {
      "to": {
        name: 'ForumCreate'
      }
    }
  }, [_vm._v(" 新規作成 ")]) : _vm._e(), _c('div', {
    staticClass: "forum-list"
  }, _vm._l(_setup.forumList, function (forum) {
    return _c('ForumItem', {
      key: forum.id,
      attrs: {
        "forum": forum
      }
    });
  }), 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };