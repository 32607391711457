var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('section', {
    staticClass: "common_modal"
  }, [_c('div', {
    staticClass: "common_modal__inner"
  }, [_c('div', {
    staticClass: "common_modal__white_frame",
    attrs: {
      "id": "targetFrame"
    }
  }, [_c('div', {
    staticClass: "common_modal__inner_frame",
    attrs: {
      "id": "modal_sentSettled"
    }
  }, [_c('h3', {
    staticClass: "common_modal__title"
  }, [_vm._t("title")], 2), _c('p', {
    staticClass: "common_modal__text"
  }, [_vm._t("message")], 2), _vm.okButton ? _c('div', {
    staticClass: "common_form__button_wrap"
  }, [_c('input', {
    staticClass: "close_button",
    attrs: {
      "type": "button",
      "value": "OK"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _setup.emit('close');
      }
    }
  })]) : _vm._e()])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };